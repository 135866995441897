<template>
  <a-row>
    <spinner v-if="loading" />
    <a-col v-show="!loading" class="height100 flex-1 faq-content">
      <a-tabs :active-key="$route.query.tab || 'content'" class="main-tabs" type="card"
        @change="$router.push({ query: { tab: arguments[0] } })">
        <a-tab-pane key="content">
          <span slot="tab"> <a-icon type="project" />{{ $t("ingradient") }}</span>

          <!-- lang tabs -->
          <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
            <a-col :span="4" class="form_title"> {{ $t("Lang") }}: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>

          <!-- title -->
          <a-row align="middle">
            <a-col :span="4" class="form_title"> {{ $t('Question') }}: </a-col>
            <a-col :span="12" class="sm-mb">
              <a-textarea v-model="form.question[langTab]" :auto-size="{ minRows: 2, maxRows: 3 }" :placeholder="$t('Question')"
                class="form_title-input" />
            </a-col>
          </a-row>
          <a-row align="middle" style="margin-bottom: 15px">
            <a-col :span="4" class="form_title"> {{ $t('Answer') }}: </a-col>
            <a-col :key="$route.query.tab" :span="16" style="margin: 0 auto">
              <!-- :key="langtab" -->
              <!-- <editor
                :key="getKey"
                :value="form.answer[langTab]"
                :initial-value="form.answer[langTab]"
                :init="{
                  ...tinymce.default.props,
                  menubar: 'file edit view insert format tools table tc help',
                  setup: (editor) => {
                    editor.ui.registry.addButton('spoiler-btn', {
                      icon: 'comment-add',
                      tooltip: 'Спойлер',
                      onAction: () => {
                        spoiler.modalVisibility = editor
                        spoilerHandler()
                      }
                    })
                    editor.ui.registry.addButton('storage-btn', {
                      icon: 'non-breaking',
                      tooltip:'Хранилище изображений',
                      onAction: () => {
                        openStoreModal()
                        editorInstance = editor
                      }
                    })
                  }
                }"
                @input="
                  form.answer[langTab] = editorChangeHandler(arguments[0])
                "
              /> -->
              <div v-show="langTab == 'oz'">
                <!-- :key="langtab" -->
                <editor :value="form.answer.oz" :initial-value="form.answer.oz" :init="{
                  ...tinymce.default.props,
                  menubar: 'file edit view insert format tools table tc help',
                  setup: (editor) => {
                    editor.ui.registry.addButton('spoiler-btn', {
                      icon: 'comment-add',
                      tooltip: 'Спойлер',
                      onAction: () => {
                        spoiler.modalVisibility = editor
                        spoilerHandler()
                      }
                    })
                    editor.ui.registry.addButton('storage-btn', {
                      icon: 'non-breaking',
                      tooltip:'Хранилище изображений',
                      onAction: () => {
                        openStoreModal()
                        editorInstance = editor
                      }
                    })
                  }
                }" @input="form.answer.oz = editorChangeHandler(arguments[0])" />
                <!-- <div v-if="isContentNull" class="error-text">
                 {{$t('EditorErrTitle')}}.
                </div> -->
              </div>
              <div v-show="langTab == 'uz'">
                <!-- :key="langtab" -->
                <editor :value="form.answer.uz" :initial-value="form.answer.uz" :init="{
                  ...tinymce.default.props,
                  menubar: 'file edit view insert format tools table tc help',
                  setup: (editor) => {
                    editor.ui.registry.addButton('spoiler-btn', {
                      icon: 'comment-add',
                      tooltip: 'Спойлер',
                      onAction: () => {
                        spoiler.modalVisibility = editor
                        spoilerHandler()
                      }
                    })
                    editor.ui.registry.addButton('storage-btn', {
                      icon: 'non-breaking',
                      tooltip:'Хранилище изображений',
                      onAction: () => {
                        openStoreModal()
                        editorInstance = editor
                      }
                    })
                  }
                }" @input="form.answer.uz = editorChangeHandler(arguments[0])" />
                <!-- <div v-if="isContentNull" class="error-text">
                 {{$t('EditorErrTitle')}}.
                </div> -->
              </div>
              <div v-show="langTab == 'ru'">
                <!-- :key="langtab" -->
                <editor :value="form.answer.ru" :initial-value="form.answer.ru" :init="{
                  ...tinymce.default.props,
                  menubar: 'file edit view insert format tools table tc help',
                  setup: (editor) => {
                    editor.ui.registry.addButton('spoiler-btn', {
                      icon: 'comment-add',
                      tooltip: 'Спойлер',
                      onAction: () => {
                        spoiler.modalVisibility = editor
                        spoilerHandler()
                      }
                    })
                    editor.ui.registry.addButton('storage-btn', {
                      icon: 'non-breaking',
                      tooltip:'Хранилище изображений',
                      onAction: () => {
                        openStoreModal()
                        editorInstance = editor
                      }
                    })
                  }
                }" @input="form.answer.ru = editorChangeHandler(arguments[0])" />
                <!-- <div v-if="isContentNull" class="error-text">
                 {{$t('EditorErrTitle')}}.
                </div> -->
              </div>
              <div v-show="langTab == 'en'">
                <!-- :key="langtab" -->
                <editor :value="form.answer.en" :initial-value="form.answer.en" :init="{
                  ...tinymce.default.props,
                  menubar: 'file edit view insert format tools table tc help',
                  setup: (editor) => {
                    editor.ui.registry.addButton('spoiler-btn', {
                      icon: 'comment-add',
                      tooltip: 'Спойлер',
                      onAction: () => {
                        spoiler.modalVisibility = editor
                        spoilerHandler()
                      }
                    })
                    editor.ui.registry.addButton('storage-btn', {
                      icon: 'non-breaking',
                      tooltip:'Хранилище изображений',
                      onAction: () => {
                        openStoreModal()
                        editorInstance = editor
                      }
                    })
                  }
                }" @input="form.answer.en = editorChangeHandler(arguments[0])" />
                <!-- <div v-if="isContentNull" class="error-text">
                 {{$t('EditorErrTitle')}}.
                </div> -->
              </div>
            </a-col>
          </a-row>
          <!-- position -->
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title"> Позиция: </a-col>
            <a-col :span="4" class="sm-mb">
              <a-input v-model.number="form.position" :placeholder="$t('positionPlac')" spellcheck="false" />
              <div v-if="!form.position" class="error-text">
                {{ $t('positionPlac') }}.
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="4" class="form_title"> {{ $t("Status") }}: </a-col>
            <a-switch v-model="form.is_active" />
          </a-row>
        </a-tab-pane>
      </a-tabs>

      <a-row class="form-buttons__action" type="flex">
        <a-col :span="3" style="text-align: right; padding-right: 8px" />
        <a-col :span="12">
          <a-button style="margin-right: 10px" @click="$router.go(-1)">
            <a-icon type="close" />{{ $t("Cancel") }}
          </a-button>
          <a-button :loading="pending" type="primary" @click="submit((routerBack = false))">
            <a-icon type="check" />
            {{ $t("Save") }}
          </a-button>
          <a-button v-if="form.id" :loading="pending" style="margin-left: 10px" type="primary"
            @click="submit((routerBack = true))">
            <a-icon type="check" />
            {{ $t("SaveAndExit") }}
          </a-button>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
export default {
  components: {
    editor: () => import("@tinymce/tinymce-vue")
  },
  data: () => ({
    langTab: $langPrefix,
    pending: false,
    loading: false,
    form: {
      question: {
        uz: "",
        oz: "",
        ru: "",
        en: ""
      },
      answer: {
        uz: "",
        oz: "",
        ru: "",
        en: ""
      },
      position: 1,
      is_active: false
    }
  }),
  computed: {
    getKey() {
      return this.langTab
    }
  },
  async mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      if (!this.$route.params.id) {
        return
      }
      this.loading = true
      try {
        const {data} = await this.$store.dispatch(
          "faq/detail",
          this.$route.params.id
        )
        this.form = {
          ...data,
        }
      } catch (error) {
        this.$message.error("Ошибка при загрузке данных")
        console.log(error)
        this.loading = false
      }
      this.loading = false
    },
    async submit(routerBack) {
      this.pending = true
      const id = this.$route.params.id
      try {
        let newData = cloneDeep(this.form)
        let f = new FormData()
        Object.keys(newData).forEach((key) => {
          if ( key == "question" || key == "answer" ) {
            f.append(key, JSON.stringify(newData[key]))
          } else {
            f.append(key, newData[key])
          }
        })
        const {status} = await this.$store.dispatch(`faq/update`, {
          id: id,
          form: f
        })
        if (status && routerBack) {
          this.$router.push({ name: "faq" })
          this.$message.success("Данные успешно сохранены")
        } else if (!routerBack) {
          this.$message.success("Данные успешно сохранены")
        }
      } catch (e) {
        this.$message.error("Ошибка при сохранении данных")
        console.log(e)
        this.pending = false
      }

      this.pending = false
    }
  }
}
</script>
